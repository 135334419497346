<template>
    <section class="Ourpartners">
    <h1>Our Partners</h1>
    
    <div class="partners">
        <img src="@/assets/partners/rotaract.png" alt="">
        <img src="@/assets/partners/rotary.png" alt="">
        <img src="@/assets/partners/patriots.jpg" alt="">
        <img src="@/assets/partners/rising.png" alt="">
        <img src="@/assets/partners/ges.png" alt="">
        
    </div>
</section>
</template>

<style>

.Ourpartners{
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.Ourpartners h1{
    text-align: center;
    text-align: center;
    padding: 30px 0px;
    margin: 0;
    color: #064e3b;
    font-size: 50px;
    font-family: 'Inter Variable', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}




.partners{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
}

.partners img{
    width: 200px;
    height: 100px;
    object-fit: contain;
}


@media screen and (max-width: 970px) {
  
    .partners{
 
    grid-template-columns: repeat(2, 1fr);
  
}
.partners img{
    width: 100%;
    padding: 1rem;
   
}


}
</style>