<template>
    
    <footer class="footer">
        <div class="containerrr">
            <div class="row">
                <div class="footer-col">
                    <h4>company</h4>
                    <ul>
                        <li><router-link :to="{name:'about'}">About us</router-link></li>
                        <li> <router-link :to="{name:'gallery'}">Gallery</router-link></li>
                        <li><router-link :to="{name:'contact'}">Contact Us</router-link></li>
                        
                    </ul>
                </div>
               
                <div class="footer-col">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><router-link :to="{name:'about'}">About us</router-link></li>
                        <li> <router-link :to="{name:'gallery'}">Gallery</router-link></li>
                        <li><router-link :to="{name:'impact'}">Impact Stories</router-link></li>
                        <li><router-link :to="{name:'contact'}">Donate</router-link></li>
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>follow us</h4>
                    <div class="social-links">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="https://twitter.com/_Dare__to_dream"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                        <a href="#"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            </div>
        </div>
   </footer>
</template>

<style scoped>

.containerrr{
	max-width: 1170px;
	margin:auto;
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
    padding-left: 0;
}

.footer{
	background-color: #f0fdfa;
    padding: 70px 0;
}
.footer-col{
  
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #134e4a;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 600;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #e91e63;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li a{
	font-size: 16px;
	text-transform: capitalize;
	color: #134e4a;
	text-decoration: none;
	font-weight: 400;
	color: #134e4a;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	color: #134e4a;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #134e4a;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #134e4a;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}






</style>