<template>
  <section class="Ourgallery">
    <h1>Our Gallery</h1>
    <p>
      Take a look at what we do and why we do them. Take a look at the images of
      the beneficiaries and our volunteers.
    </p>
    <router-link :to="{ name: 'gallery' }">View All</router-link>
    <div class="gallery">
      <img src="@/assets/gallery/1.jpg" alt="" />
      <img src="@/assets/gallery/2.jpg" alt="" />
      <img src="@/assets/gallery/3.jpg" alt="" />
      <img src="@/assets/gallery/4.jpg" alt="" />
      <img src="@/assets/gallery/5.jpg" alt="" />
      <img src="@/assets/gallery/6.jpg" alt="" />
      <img src="@/assets/queen/3.jpg" alt="" />
      <img src="@/assets/queen/5.jpg" alt="" />
      <img src="@/assets/fire/8.jpg" alt="" />
      <img src="@/assets/fire/1.jpg" alt="" />
    </div>
  </section>
</template>

<style>
.Ourgallery {
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.Ourgallery h1 {
  text-align: center;
  text-align: center;
  padding: 30px 0px;
  margin: 0;
  color: #064e3b;
  font-size: 50px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.Ourgallery p {
  text-align: center;
  width: 500px;
  text-align: center;

  margin: 0;
  font-size: 18px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 190%;
}

.Ourgallery a {
  padding: 30px 0px;
  text-decoration: underline;
  color: #064e3b;
  font-size: 24px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 800;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 95%;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.gallery img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@media (max-width: 1240px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 1440px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width: 970px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .gallery img {
    width: 100%;
    padding: 1rem;
  }
  .Ourgallery p {
    width: 100vw;
    padding: 1rem;
  }
}
</style>
