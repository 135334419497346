<template>
  <homeNav />
  <section class="landingPage">
    <div class="landingSection"></div>
    <div class="landing">
      <div class="landingText">
        <h1
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          Removing Barriers to Learning
        </h1>
        <p
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          We are committed to providing quality education, improving
          accessibility and delivering measurable results.
        </p>
        <div class="buttons">
          <router-link :to="{ name: 'contact' }"> Connect with us </router-link>
          <router-link :to="{ name: 'contact' }"> Donate </router-link>
        </div>
      </div>
      <div class="landingImage" data-aos="fade-left" data-aos-duration="1500">
        <img src="@/assets/homeview/layy.png" alt="" />
      </div>
    </div>
  </section>
  <section class="about">
    <div class="aboutUs">
      <div
        class="au"
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <h3>About Us</h3>
        <h1>Equity in Education</h1>
        <P
          >We bring equity to the quality of learning in education. We design
          and facilitate implementation of bespoke curriculum for schools to
          embody their peculiar challenges and strengths. We give teachers and
          facilitators tools to incorporate contemporary approaches to existing
          pedagogy. We create contextualised curriculum and experiential
          learning environments in public schools.
        </P>
        <ul>
          <li>
            We create STEM learning experiences in public schools through
            spatial learning.
          </li>
          <li>
            We design bespoke curriculum for schools to embody their peculiar
            challenges and strengths.
          </li>
          <li>
            We train teachers and facilitators to incorporate Spatial learning
            into existing pedagogical approach.
          </li>
        </ul>
        <router-link :to="{ name: 'about' }">Learn More</router-link>
      </div>
      <div
        class="auImage"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <img src="@/assets/homeview/about.png" alt="" />
      </div>
    </div>
  </section>
  <section class="focus">
    <div class="ourfocus">
      <div
        class="auImage"
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <img src="@/assets/homeview/focuss.png" alt="" />
      </div>
      <div
        class="au"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <h3>Our Focus</h3>
        <h1>We are the bridge to quality learning in education</h1>
        <P
          >Our approach is focusing on creating special curriculums for the
          students and schools to reflect their specific needs. We want to
          enrich the learning experiences of children in public schools while
          advancing the pedagogical experience of teachers.</P
        >
        <router-link :to="{ name: 'about' }">Learn More</router-link>
      </div>
    </div>
  </section>
  <section class="services">
    <h1 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
      Our Impact In Numbers
    </h1>
    <p data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
      We work with educators, parents, and schools to provide a customized
      experience for students and families. We aim to create better outcomes for
      all involved.
    </p>
    <div class="service">
      <div class="serviceCards">
        <div
          class="serviceCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <h2>3+</h2>
          <h3>Schools</h3>
        </div>
        <div
          class="serviceCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <h2>500+</h2>
          <h3>Students</h3>
        </div>
        <div
          class="serviceCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <h2>30+</h2>
          <h3>Teachers</h3>
        </div>
      </div>
    </div>
  </section>
  <section class="impacts">
    <h1 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
      Our Impact Stories
    </h1>
    <div class="impact">
      <div class="impactCards">
        <div
          class="impactCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img src="@/assets/fire/4.jpg" alt="" />
          <h2>Fire Armor 2 Basic School</h2>
          <p>
            According to the Ghana Facts sheet, by 2020 there were about 3
            million children without satisfactory foundational literacy and
            numeracy skills at the basic level of education in Ghana. In
            reality, this means there are over 3 million hopes, dreams, smiles
            and futures on the line. There is much to be done but it is not an
            insurmountable feat. Anidaso is excited to make change one hope, one
            smile, one dream and one child at a time....
          </p>
          <router-link :to="{ name: 'fire' }">Read More</router-link>
        </div>
        <div
          class="impactCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img src="@/assets/queen/6.jpg" alt="" />
          <h2>Queen of Peace Basic school</h2>
          <p>
            The statistics on the lack of Foundational Literacy and numeracy
            especially in Ghana can be daunting and scary. However seeing
            statistics can make us feel far removed from the reality, the
            reality that these numbers represent actual people. These numbers
            represent people that may not be able to fully realise their
            potential without extra support. Anidaso Foundation was privileged
            to once again come face to face with the children that represent
            these statistics....
          </p>
          <router-link :to="{ name: 'queen' }">Read More</router-link>
        </div>

        <div
          class="impactCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img src="@/assets/impact/madina.jpg" alt="" />
          <h2>Anidaso Foundation & Ghana Girl Rising</h2>
          <p>
            Broken chairs and tables, lack of text books, cupboards falling
            apart, limited access to technology and limited extracurricular
            activities paints a picture that is far from an ideal learning
            environment. Unfortunately, this depicts...
          </p>
          <router-link :to="{ name: 'girl_rising' }">Read More</router-link>
        </div>
        <div
          class="impactCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img src="@/assets/impact/teacher.jpg" alt="" />
          <h2>Anidaso Foundation & Patriots Ghana</h2>
          <p>
            Empower minds with financial literacy, and watch dreams take flight
            on the wings of informed choices. Akufful-Krodua, a beautiful
            farming village in Central Region with fertile land for farming and
            a key producer of gari amongst the neighboring towns....
          </p>
          <router-link :to="{ name: 'patriots' }">Read More</router-link>
        </div>
        <div
          class="impactCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img src="@/assets/gallery/9.jpg" alt="" />
          <h2>Anidaso Foundation & Rotary Ghana</h2>
          <p>
            On the International Day of the Girl Child, Rotary Ghana made a bold
            statement by empowering girls in Junior High School about the
            importance of digitization in education and STEM careers....
          </p>
          <router-link :to="{ name: 'rotary' }">Read More</router-link>
        </div>
        <div
          class="impactCard"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img src="@/assets/gallery/3.jpg" alt="" />
          <h2>Anidaso Foundation</h2>
          <p>
            I have always wanted to impact education in public schools and that
            afternoon was the NGO’s first solo project, so a lot was on the line
            for me. The Anidasor team took the students through some songs and
            ice breaker games to start with. The team had already developed a
            bespoke session....
          </p>
          <router-link :to="{ name: 'anidaso' }">Read More</router-link>
        </div>
      </div>
    </div>
  </section>
  <Gallery />
  <Partners />
  <section class="newsletter-signup">
    <div class="newsletter-signup__content">
      <header>
        <h3>Join The Movement</h3>
        <div class="newsletter-signup__header__content">
          <p>
            Our vision is to provide immersive, meaningful, and quality
            education that helps our students succeed in a fast-changing and
            complex world. We are working hard to be the change we want to see
            in the world. Join our worldwide movement by making a donation right
            away.
          </p>
          <p>Without your generous donations, we can do nothing.</p>
          <p>("Hope is a verb with its shirtsleeves rolled up." – David Orr)</p>
        </div>
      </header>

      <div class="newsletter-signup__form-panel">
        <router-link :to="{ name: 'contact' }" class="button"
          >DONATE</router-link
        >
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/navbar.vue";
import homeNav from "@/components/homeNav.vue";

import Footer from "@/components/footer.vue";
import Gallery from "@/components/gallery.vue";
import Partners from "@/components/partners.vue";

export default {
  name: "HomeView",
  components: {
    Navbar,
    homeNav,
    Footer,
    Gallery,
    Partners,
  },
};
</script>
<style scoped>
.buttons {
  display: flex;
  padding-bottom: 100px !important;
  gap: 45px;
  margin: 0;
  padding: 0;
}
.landingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  width: 100vw;
  padding-top: 10rem;
  padding-bottom: 5rem;
  background-image: url(@/assets/queen/8s.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.landingSection {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.landing {
  display: flex;
  justify-content: center;

  align-items: center;
  align-self: center;
  width: 90%;
}

.landingText {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.landingImage {
  width: 50%;
}

.landingImage img {
  width: 100%;
  display: none;
}
.landingText h1 {
  font-family: "Inter Variable", sans-serif;
  color: #fff;
  margin: 0;
  text-align: left;
  font-size: 4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding: 0;
}

.landingText h1 span {
  color: #f24e1e;
}

.landingText p {
  text-align: left;

  font-size: 1.5vw;
  font-family: "Inter Variable", sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

.landingText a {
  display: inline-flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}

@media (max-width: 1240px) {
  .landingImage img {
    width: 450px;
  }
}

@media (max-width: 1440px) {
  .landingText h1 {
    font-family: "Inter Variable", sans-serif;
    color: #fff;
    margin: 0;
    text-align: left;
    font-size: 46px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.023%;
  }
}

@media screen and (max-width: 970px) {
  .landingImage img {
    width: 100%;
  }
  .buttons {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
  .landing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100vw;
    gap: 20px;
    flex-direction: column;
    padding-top: 0px;
  }
  .landingText {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .landingImage {
    width: 100vw;
    padding: 1rem;
  }
  .landingText h1 {
    text-align: center;
    font-size: 42px;
  }
  .landingText p {
    text-align: center;
    font-size: 18px;
  }
  .landingText a {
    display: inline-flex;
    padding: 8px;
    font-size: 1.5rem;
    width: fit-content;
    align-self: center;
  }
  .landingText {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 1rem;
  }
  .landingPage {
    padding-top: 8rem;
  }
}

.about {
  display: flex;
  justify-content: center;
  margin: 100px 0px;
  width: 100vw;
}

.aboutUs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  text-align: left;
}

.au {
  width: 600px;
}

.auImage {
  width: 600px;
}
.auImage img {
  width: 600px;
}

.au h3 {
  color: #418b88;
  margin: 8px 0px;
}

.au h1 {
  color: #064e3b;
  font-size: 48px;
  margin: 8px 0px;

  font-family: "Inter Variable", sans-serif;

  margin: 30px 0;
  text-align: left;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.au p {
  margin: 8px 0px 3rem;
  text-align: left;

  font-size: 18px;
  font-family: "Inter Variable", sans-serif;

  font-style: normal;
  font-weight: 500;
  line-height: 190%;
}

.aboutUs ul {
  margin: 8px 0px 3rem;
  padding-left: 30px;
  text-align: left;
  font-size: 18px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 190%;
}
.au a {
  width: 185px;
  height: 48px;
  background: #064e3b;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: var(--body-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 29.5px;
  margin-top: 5px;
}

.focus {
  display: flex;
  justify-content: center;
  padding: 100px 0px;
  width: 100vw;
  background-color: #f0fdfa;
}

.ourfocus {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  text-align: left;
}

@media screen and (max-width: 970px) {
  .containerr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .about {
    height: auto;
    padding: 3rem 1rem 1rem 1rem;
  }
  .aboutUs {
    flex-direction: column;
  }
  .focus {
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ourfocus {
    flex-direction: column;
  }
  .au {
    width: auto;
  }

  .auImage {
    width: auto;
  }
  .auImage img {
    width: 100%;
  }
}

@media (max-width: 1240px) {
  .about {
    height: auto;
    padding: 3rem 1rem 1rem 1rem;
  }
  .aboutUs {
    width: 95%;
  }

  .focus {
    height: auto;
    padding: 3rem 1rem 1rem 1rem;
  }
  .ourfocus {
    width: 95%;
  }
}

@media (max-width: 1440px) {
  .about {
    height: auto;
    padding: 3rem 1rem 1rem 1rem;
  }
  .aboutUs {
    width: 90%;
  }

  .focus {
    height: auto;
    padding: 3rem 1rem 1rem 1rem;
  }
  .ourfocus {
    width: 90%;
  }
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 100px 0 10px 0px;
  flex-direction: column;
}
.services h1 {
  text-align: center;
  padding: 30px 0px;

  margin: 0;
  color: #064e3b;
  font-size: 50px;

  font-family: "Inter Variable", sans-serif;

  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.services p {
  width: 600px;
  text-align: center;
  padding-bottom: 50px;
  margin: 0;
  font-size: 18px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 190%;
}

.service {
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: center;
  align-self: center;
}

.serviceCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.serviceCard {
  padding: 2rem 5rem;

  background-color: #f0fdfa;
  border-radius: 8px;
}

.serviceCard h2 {
  margin: 1rem 0px;
  color: #064e3b;
  font-size: 50px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.serviceCard img {
  width: 100px;
}
@media screen and (max-width: 970px) {
  .serviceCards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
  .services {
    height: auto;
    margin-top: 3rem;
  }
  .services p {
    width: 100vw;
    padding: 1rem;
  }
}

.impacts {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 100px 0px;
  flex-direction: column;
  text-align: left;
}
.impacts h1 {
  text-align: center;
  padding: 30px 0px;
  margin: 0;
  color: #064e3b;
  font-size: 50px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.impact {
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: center;
  align-self: center;
}

.impactCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding-top: 50px;
}
.impactCard {
  padding: 2rem;
  padding-bottom: 2rem;
  background-color: #f0fdfa;
  border-radius: 8px;
}
.impactCard h2 {
  margin: 1rem 0px;
  color: #418b88;
}
.impactCard img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.impactCard p {
  width: 400px;
  padding-bottom: 1rem;
  font-size: 18px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 190%;
}
.impactCard a {
  text-decoration: none;
  font-weight: 800;
  color: #064e3b;
}

@media (max-width: 1240px) {
  .impactCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1440px) {
  .impactCards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 970px) {
  .impacts {
    height: auto;
  }
  .impact {
    width: auto;
  }
  .impactCards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    width: 100vw;
  }
  .impactCard img {
    width: 100%;
    height: 200px;
  }
  .impactCard h2 {
    padding: 1rem;
  }
  .impactCard p {
    padding: 1rem;
    width: auto;
  }
  .impactCard a {
    padding: 1rem;
  }
}

.newsletter-signup {
  background-image: url(@/assets/queen/7s.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  --input-height: 4.6rem;
  --grid-gap: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: 5rem;
}

.newsletter-signup .newsletter-signup__content {
  --grid-gap: 3rem;
  --pager-width: 12rem;
  width: min(var(--readable-width), 100vw - var(--grid-gap) * 2);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  align-items: center;
  text-align: center;
}

.newsletter-signup .newsletter-signup__content header {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
}
.newsletter-signup__header__content {
  font-size: 18px;
  line-height: 2.2rem;
  font-weight: 400;
  width: 600px;
}
.newsletter-signup__header__content p {
  padding: 0;
  margin: 0;
}
.newsletter-signup__form-panel a {
  width: 185px;
  height: 48px;
  background: #064e3b;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: var(--body-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 29.5px;
  margin-top: 5px;
}

@media screen and (max-width: 970px) {
  .newsletter-signup__header__content {
    width: auto;
  }
}
</style>
